import React from "react";
import "twin.macro";
import { SEO } from "../components";
import {
  BlankPageNoImage,
  LocationBlock,
  ProseWrapper,
} from "../components/common";

const intro = `
In order to check the health of the ears visually a piece of equipment called an otoscope is used to allow the audiologist to view the ears under magnified lighting.
<br /> <br />
During otoscopy the audiologist is looking to establish how much wax there is in the ears, that the skin of the ear canals is healthy, that there is no current ear infection, that the ear drums are intact and appear healthy, that there are no foreign bodies present in the ear canals and generally that there are no abnormalities.
`;

const Otoscopy = () => {
  return (
    <>
      <SEO title="Video Otoscopy" />
      <BlankPageNoImage
        header="Video Otoscopy"
        intro={intro}
        ariaLabel="Video Otoscopy"
      >
        <ProseWrapper>
          <div>
            <h4>Video Otoscopy</h4>
            <p>
              With our high quality video otoscopes, images can be taken of the
              ear and can be saved to patient records or used for referral
              purposes if needed. This also allows for a more comprehensive
              consultation with increased interaction as the patient gets to see
              just what the audiologist sees.
            </p>
          </div>
        </ProseWrapper>
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default Otoscopy;
